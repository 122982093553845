import React from 'react';
import {
	Card,
	CardContent,
	Typography,
	Box,
	Chip,
	IconButton,
} from '@mui/material';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { OpenInNew } from '@mui/icons-material';

const ChatSessionCard = ({ session, onClick }) => {
	return (
		<Card
			sx={{
				mb: 2,
				cursor: 'pointer',
				'&:hover': { transform: 'translateY(-2px)' },
				transition: 'transform 0.2s',
			}}
		>
			<CardContent>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography variant="subtitle2" color="text.secondary">
						{format(
							new Date(session.lastActivity),
							'dd.MM.yyyy HH:mm',
							{
								locale: fi,
							},
						)}
					</Typography>
					<Chip
						label={`${session.messageCount} viestiä`}
						size="small"
						color="primary"
						variant="outlined"
					/>
				</Box>
				<Typography
					variant="body1"
					sx={{
						mt: 1,
						mb: 2,
						display: '-webkit-box',
						WebkitLineClamp: 2,
						WebkitBoxOrient: 'vertical',
						overflow: 'hidden',
					}}
				>
					{session.summary}
				</Typography>
				<Box display="flex" justifyContent="flex-end">
					<IconButton size="small" onClick={onClick}>
						<OpenInNew />
					</IconButton>
				</Box>
			</CardContent>
		</Card>
	);
};

export default ChatSessionCard;
