import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleColorMode from './ToggleColorMode';
import logo from '../../../logo.png';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'features/User/userSlice';
import { useLogout } from 'hooks/useLogout';
import Avatar from '@mui/material/Avatar';

export const scrollToSection = (sectionId, setOpen) => {
	const sectionElement = document.getElementById(sectionId);
	const offset = 128;
	if (sectionElement) {
		const targetScroll = sectionElement.offsetTop - offset;
		sectionElement.scrollIntoView({ behavior: 'smooth' });
		window.scrollTo({
			top: targetScroll,
			behavior: 'smooth',
		});
		if (setOpen) setOpen(false); // Close the drawer if setOpen is provided
	}
};

function AppAppBar({ userRole, setUserRole }) {
	const [open, setOpen] = React.useState(false);
	const navigate = useNavigate();
	const user = useSelector(selectUser);
	const dispatch = useDispatch();
	const logout = useLogout();

	const theme = useTheme();
	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};

	const handleRoleChange = (event, newRole) => {
		if (newRole !== null) {
			setUserRole(newRole);
			sessionStorage.setItem('userRole', newRole);
		}
	};

	const handleLogout = () => {
		logout();
		navigate('/login');
	};

	return (
		<AppBar
			position="fixed"
			sx={{
				boxShadow: 0,
				bgcolor: 'transparent',
				backgroundImage: 'none',
				mt: 2,
			}}
		>
			<Container maxWidth="lg">
				<Toolbar
					variant="regular"
					sx={(theme) => ({
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexShrink: 0,
						borderRadius: '999px',
						backdropFilter: 'blur(24px)',
						maxHeight: 40,
						borderColor: 'divider',
						bgcolor: 'transparent',
					})}
				>
					<Box
						sx={{
							flexGrow: 1,
							display: 'flex',
							alignItems: 'center',
							px: 0,
						}}
					>
						<Box
							component="img"
							sx={{
								height: 53,
								paddingRight: 3,
								cursor: 'pointer',
							}}
							alt="logo"
							src={logo}
						/>
						<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
							<Button
								variant="text"
								color="info"
								size="small"
								onClick={() => scrollToSection('features')}
							>
								Ominaisuudet
							</Button>
							<Button
								variant="text"
								color="info"
								size="small"
								onClick={() => scrollToSection('testimonials')}
							>
								Kokemukset
							</Button>
							{userRole === 'student' && (
								<Button
									variant="text"
									color="info"
									size="small"
									onClick={() =>
										scrollToSection('recruitments')
									}
								>
									Avoimet paikat
								</Button>
							)}
							<Button
								variant="text"
								color="info"
								size="small"
								onClick={() => scrollToSection('contact')}
								sx={{ minWidth: 0 }}
							>
								Yhteystiedot
							</Button>
						</Box>
					</Box>
					<Box
						sx={{
							display: { xs: 'none', md: 'flex' },
							gap: 0.5,
							alignItems: 'center',
						}}
					>
						<ToggleButtonGroup
							value={userRole}
							exclusive
							onChange={handleRoleChange}
							aria-label="User role"
							size="small"
							sx={{
								mr: 2,
								padding: '4px',
								borderRadius: '50px',
								'& .MuiToggleButton-root': {
									borderRadius: '50px',
									border: 'none',
									backgroundColor: 'transparent',
									'&:hover': {
										backgroundColor:
											'rgba(255, 255, 255, 0.05)',
									},
									'&.Mui-selected': {
										backgroundColor:
											theme.palette.primary.light,
									},
								},
							}}
						>
							<ToggleButton
								size="small"
								value="company"
								aria-label="company"
								sx={{ padding: '5px 10px' }}
							>
								Yritys
							</ToggleButton>
							<ToggleButton
								size="small"
								value="student"
								aria-label="student"
								sx={{ padding: '5px 10px' }}
							>
								Opiskelija
							</ToggleButton>
						</ToggleButtonGroup>
						{user ? (
							<>
								{user.role === 'admin' && (
									<Button
										color="primary"
										variant="contained"
										onClick={() => navigate('/app')}
									>
										Dashboard
									</Button>
								)}
								{user.role === 'talent' && (
									<Button
										color="primary"
										variant="contained"
										onClick={() => navigate('/app')}
									>
										Profiili
									</Button>
								)}
								{user.role === 'company' && (
									<Button
										color="primary"
										variant="contained"
										onClick={() => navigate('/talent-chat')}
									>
										Löydä Talentti
									</Button>
								)}
								<IconButton onClick={handleLogout}>
									<Avatar src={user.avatar} alt={user.name} />
								</IconButton>
							</>
						) : (
							<Button
								color="primary"
								variant="contained"
								onClick={() => navigate('/login')}
							>
								Kirjaudu sisään
							</Button>
						)}
					</Box>
					<Box sx={{ display: { sm: 'flex', md: 'none' } }}>
						<IconButton
							aria-label="Menu button"
							onClick={toggleDrawer(true)}
						>
							<MenuIcon />
						</IconButton>
						<Drawer
							anchor="top"
							open={open}
							onClose={toggleDrawer(false)}
						>
							<Box
								sx={{
									p: 2,
									backgroundColor: 'background.default',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<IconButton onClick={toggleDrawer(false)}>
										<CloseRoundedIcon />
									</IconButton>
								</Box>
								<Divider sx={{ my: 3 }} />
								<MenuItem
									onClick={() => scrollToSection('features')}
								>
									Ominaisuudet
								</MenuItem>
								<MenuItem
									onClick={() =>
										scrollToSection('testimonials')
									}
								>
									Asiakaspalautteet
								</MenuItem>
								{userRole === 'student' && (
									<MenuItem
										onClick={() =>
											scrollToSection('recruitments')
										}
									>
										Avoimet rekryt
									</MenuItem>
								)}
								<MenuItem
									onClick={() => scrollToSection('contact')}
								>
									Yhteystiedot
								</MenuItem>
								<MenuItem>
									{user ? (
										<>
											{user.role === 'admin' && (
												<Button
													color="primary"
													variant="contained"
													fullWidth
													onClick={() =>
														navigate('/dashboard')
													}
												>
													Dashboard
												</Button>
											)}
											{user.role === 'student' && (
												<Button
													color="primary"
													variant="contained"
													fullWidth
													onClick={() =>
														navigate('/profile')
													}
												>
													Profiili
												</Button>
											)}
											{user.role === 'company' && (
												<Button
													color="primary"
													variant="contained"
													fullWidth
													onClick={() =>
														navigate('/find-talent')
													}
												>
													Löydä tekijä
												</Button>
											)}
											<IconButton onClick={handleLogout}>
												<Avatar
													src={user.avatar}
													alt={user.name}
												/>
											</IconButton>
										</>
									) : (
										<Button
											color="primary"
											variant="contained"
											fullWidth
											onClick={() => navigate('/login')}
										>
											Kirjaudu sisään
										</Button>
									)}
								</MenuItem>
								<MenuItem>
									<ToggleButtonGroup
										value={userRole}
										exclusive
										onChange={handleRoleChange}
										aria-label="User role"
										fullWidth
										size="small"
									>
										<ToggleButton
											value="company"
											aria-label="company"
										>
											Yritys
										</ToggleButton>
										<ToggleButton
											value="student"
											aria-label="student"
										>
											Opiskelija
										</ToggleButton>
									</ToggleButtonGroup>
								</MenuItem>
							</Box>
						</Drawer>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}

AppAppBar.propTypes = {
	userRole: PropTypes.oneOf(['company', 'student', 'admin']).isRequired,
	setUserRole: PropTypes.func.isRequired,
};

export default AppAppBar;
