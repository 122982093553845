import React, { useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	Box,
	Chip,
	IconButton,
	Link,
	Divider,
	CircularProgress,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { Link as RouterLink } from 'react-router-dom';
import { useChatSession } from '../hooks/useChatSessions';

const ChatSessionDetailsModal = ({ session, open, onClose }) => {
	const { chatSession, isLoading } = useChatSession(
		open ? session?.id : null,
	);

	if (!session) return null;

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogTitle>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography variant="h6">Chat-session tiedot</Typography>
					<IconButton onClick={onClose} size="small">
						<Close />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				{isLoading ? (
					<Box display="flex" justifyContent="center" p={3}>
						<CircularProgress />
					</Box>
				) : (
					<>
						<Box mb={3}>
							<Typography
								variant="subtitle2"
								color="text.secondary"
							>
								Viimeisin aktiviteetti
							</Typography>
							<Typography>
								{format(
									new Date(session.lastActivity),
									'dd.MM.yyyy HH:mm',
									{
										locale: fi,
									},
								)}
							</Typography>
						</Box>

						<Box mb={3}>
							<Typography
								variant="subtitle2"
								color="text.secondary"
							>
								Tila
							</Typography>
							<Chip
								label={
									session.status === 'active'
										? 'Aktiivinen'
										: 'Päättynyt'
								}
								color={
									session.status === 'active'
										? 'success'
										: 'default'
								}
								size="small"
							/>
						</Box>

						{session.summary && (
							<Box mb={3}>
								<Typography
									variant="subtitle2"
									color="text.secondary"
									gutterBottom
								>
									Yhteenveto
								</Typography>
								<Typography>{session.summary}</Typography>
							</Box>
						)}

						{session.suggestedTalents.length > 0 && (
							<Box mb={3}>
								<Typography
									variant="subtitle2"
									color="text.secondary"
									gutterBottom
								>
									Ehdotetut talentit
								</Typography>
								{session.suggestedTalents.map((talent) => (
									<Box key={talent.talentId} mb={1}>
										<Typography variant="body2">
											<Link
												component={RouterLink}
												to={`/app/talents/${talent.talentId}`}
												sx={{ textDecoration: 'none' }}
											>
												Katso profiili
											</Link>
											<Chip
												label={`${(talent.score * 100).toFixed(1)}%`}
												size="small"
												sx={{ ml: 1 }}
											/>
										</Typography>
									</Box>
								))}
							</Box>
						)}

						{chatSession?.messages && (
							<Box mb={3}>
								<Typography
									variant="subtitle2"
									color="text.secondary"
									gutterBottom
								>
									Keskustelu
								</Typography>
								<Box
									sx={{
										maxHeight: '400px',
										overflowY: 'auto',
										bgcolor: 'grey.50',
										p: 2,
										borderRadius: 1,
									}}
								>
									{chatSession.messages.map(
										(message, index) => (
											<Box
												key={index}
												mb={2}
												sx={{
													bgcolor: 'white',
													p: 2,
													borderRadius: 1,
													boxShadow: 1,
												}}
											>
												<Typography
													variant="caption"
													color="text.secondary"
												>
													{message.role ===
													'assistant'
														? 'Assistentti'
														: 'Asiakas'}
												</Typography>
												<Typography variant="body2">
													{message.content}
												</Typography>
												<Typography
													variant="caption"
													color="text.secondary"
													display="block"
													mt={1}
												>
													{format(
														new Date(
															message.timestamp,
														),
														'dd.MM.yyyy HH:mm',
														{ locale: fi },
													)}
												</Typography>
											</Box>
										),
									)}
								</Box>
							</Box>
						)}

						<Box>
							<Typography
								variant="subtitle2"
								color="text.secondary"
								gutterBottom
							>
								Viestien määrä
							</Typography>
							<Typography>
								{session.messageCount} viestiä
							</Typography>
						</Box>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default ChatSessionDetailsModal;
