import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Card,
	CardContent,
	Typography,
	Button,
	Box,
	Chip,
	Grid,
	Divider,
	useTheme,
	Paper,
	Alert,
	Stack,
	LinearProgress,
	Tooltip,
	IconButton,
	Avatar,
} from '@mui/material';
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	ResponsiveContainer,
} from 'recharts';
import SchoolIcon from '@mui/icons-material/School';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PersonIcon from '@mui/icons-material/Person';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import EventIcon from '@mui/icons-material/Event';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import EmailIcon from '@mui/icons-material/Email';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';

// Määritellään NODE_ENV tarkistus
const isDevelopment = process.env.NODE_ENV === 'development';

const TalentCard = ({
	talent,
	match,
	isSelected,
	onSelect,
	showInterviewButton,
	interviewSlots = [],
	isAuthenticated,
	onFeedback,
}) => {
	if (!talent) {
		console.error('TalentCard: talent prop is missing');
		return null;
	}

	const [showSlots, setShowSlots] = useState(false);
	const [feedback, setFeedback] = useState(null);
	const theme = useTheme();

	const {
		matchScore,
		skillMatch,
		roleMatch,
		availabilityScore,
		vectorScore,
	} = useMemo(() => {
		const score = (match?.score || 0) * 100;
		const details = match?.matchDetails || {};

		return {
			matchScore: score,
			skillMatch: details.skillMatchPercentage || 0,
			roleMatch: (details.roleMatchPercentage || 0) / 40,
			availabilityScore: (details.availabilityScore || 0) * 100,
			vectorScore: (details.vectorScore || 0) * 100,
		};
	}, [match]);

	const teamRoleData = useMemo(
		() =>
			Object.keys(talent.teamrole || {}).map((role) => ({
				subject: role.charAt(0).toUpperCase() + role.slice(1),
				A: talent.teamrole[role],
			})),
		[talent.teamrole],
	);

	const handleFeedback = (type) => {
		setFeedback(type);
		if (onFeedback) {
			onFeedback(talent._id, type);
		}
	};

	if (isDevelopment) {
		console.log('TalentCard rendered:', {
			talent: talent.id,
			matchScore,
			skillMatch,
			roleMatch,
			availabilityScore,
			vectorScore,
		});
	}

	// Järjestä tiimiroolit suurimmasta pienimpään
	const topRoles = Object.entries(talent.teamrole || {})
		.sort(([, a], [, b]) => b - a)
		.slice(0, 3);

	const emailSubject = `Kiinnostus tekijästä ${talent?.user?.name}`;
	const emailBody = `Hei,\n\nOlen kiinnostunut tekijästä ${talent?.user?.name}. Haluaisin keskustella lisää mahdollisesta yhteistyöstä.\n\nYstävällisin terveisin,\n[Sinun nimesi]`;

	return (
		<Card
			sx={{
				mb: 2,
				position: 'relative',
				border: isSelected
					? `2px solid ${theme.palette.primary.main}`
					: 'none',
			}}
		>
			<CardContent>
				{/* Header */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						mb: 2,
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
						<Avatar
							alt={talent?.user?.name}
							src={talent?.profilePicture}
							sx={{ width: 56, height: 56 }}
						/>
						<Box>
							<Typography variant="h6" gutterBottom>
								{talent?.name || `Talentti ${talent.id}`}
							</Typography>
							<Typography variant="body2" color="text.secondary">
								{talent?.university}
							</Typography>
							<Typography variant="body2" color="text.secondary">
								{talent?.major}
							</Typography>
							<Button
								component={Link}
								to={`/app/talents/${talent._id}`}
								size="small"
								sx={{ mt: 1 }}
								startIcon={<PersonSearchIcon />}
								target="_blank"
								rel="noopener noreferrer"
							>
								Katso profiili
							</Button>
						</Box>
					</Box>
					<Box sx={{ textAlign: 'right' }}>
						<Typography variant="h6" color="primary">
							{isAuthenticated
								? `${talent.hourlyRate || 45}€/h`
								: '45€/h'}
							{!isAuthenticated && (
								<Typography
									variant="caption"
									sx={{ display: 'block', opacity: 0.7 }}
								>
									(arvio)
								</Typography>
							)}
						</Typography>
					</Box>
				</Box>

				<Divider sx={{ my: 2 }} />

				{/* Skills */}
				<Typography variant="subtitle1" gutterBottom>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<WorkspacePremiumIcon color="primary" />
						Taidot
					</Box>
				</Typography>
				<Box
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						gap: 1,
						mb: 3,
					}}
				>
					{Object.entries(talent.skills || {}).map(([_, skill]) => (
						<Chip
							key={skill.skill}
							label={skill.skill}
							size="small"
							sx={{
								backgroundColor: theme.palette.primary.light,
								color: theme.palette.primary.contrastText,
								'&:hover': {
									backgroundColor: theme.palette.primary.main,
								},
								fontWeight: 500,
								border: `1px solid ${theme.palette.primary.main}`,
							}}
						/>
					))}
				</Box>

				<Divider sx={{ my: 2 }} />

				{/* Team Role Chart */}
				<Typography variant="subtitle1" gutterBottom>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<PersonIcon color="primary" />
						Tiimirooli
					</Box>
				</Typography>
				<Box sx={{ height: 250, width: '100%', mt: 2 }}>
					<ResponsiveContainer>
						<RadarChart data={teamRoleData}>
							<PolarGrid />
							<PolarAngleAxis
								dataKey="subject"
								tick={{
									fill: theme.palette.text.secondary,
									fontSize: 12,
								}}
							/>
							<Radar
								name="Rooli"
								dataKey="A"
								stroke={theme.palette.primary.main}
								fill={theme.palette.primary.main}
								fillOpacity={0.6}
							/>
						</RadarChart>
					</ResponsiveContainer>
				</Box>

				{/* {match && (
					<>
						<Box sx={{ mb: 2 }}>
							<Typography variant="subtitle1" gutterBottom>
								Sopivuus tehtävään: {matchScore.toFixed(1)}%
							</Typography>
							<LinearProgress
								variant="determinate"
								value={matchScore}
								sx={{ height: 10, borderRadius: 5 }}
							/>
						</Box>

						<Box sx={{ mb: 2 }}>
							<Typography variant="subtitle2" gutterBottom>
								Sopivuuden yksityiskohdat:
							</Typography>
							<Box
								sx={{
									display: 'flex',
									gap: 1,
									flexWrap: 'wrap',
								}}
							>
								<Tooltip title="Taitojen vastaavuus projektin vaatimuksiin">
									<Chip
										label={`Taidot: ${skillMatch.toFixed(1)}%`}
										color="primary"
										variant="outlined"
										size="small"
									/>
								</Tooltip>
								<Tooltip title="Tiimiroolin sopivuus">
									<Chip
										label={`Tiimirooli: ${roleMatch.toFixed(1)}%`}
										color="primary"
										variant="outlined"
										size="small"
									/>
								</Tooltip>
								<Tooltip title="Saatavuus projektin aikana">
									<Chip
										label={`Saatavuus: ${availabilityScore.toFixed(1)}%`}
										color="primary"
										variant="outlined"
										size="small"
									/>
								</Tooltip>
								<Tooltip title="Tekoälyn arvio sopivuudesta">
									<Chip
										label={`AI Match: ${vectorScore.toFixed(1)}%`}
										color="primary"
										variant="outlined"
										size="small"
									/>
								</Tooltip>
							</Box>
						</Box>
					</>
				)} */}

				{/* Olemassa olevat tiedot */}
				<Typography variant="body2" color="text.secondary" gutterBottom>
					{talent.description}
				</Typography>

				{/* Feedback buttons */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						gap: 2,
						mt: 2,
						mb: 2,
					}}
				>
					<IconButton
						onClick={() => handleFeedback('like')}
						color={feedback === 'like' ? 'primary' : 'default'}
						disabled={feedback !== null}
					>
						<ThumbUpIcon />
					</IconButton>
					<IconButton
						onClick={() => handleFeedback('dislike')}
						color={feedback === 'dislike' ? 'error' : 'default'}
						disabled={feedback !== null}
					>
						<ThumbDownIcon />
					</IconButton>
				</Box>

				{/* Action Button */}
				<Box
					sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}
				>
					{showInterviewButton && (
						<Box sx={{ mt: 3, width: '100%' }}>
							{!showSlots ? (
								<Button
									variant="contained"
									color="primary"
									fullWidth
									onClick={() => setShowSlots(true)}
									startIcon={<EventIcon />}
								>
									Valitse haastatteluun
								</Button>
							) : (
								<>
									<Typography
										variant="subtitle2"
										gutterBottom
										sx={{ mb: 2 }}
									>
										Valitse sopiva haastatteluaika:
									</Typography>
									<Box
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											gap: 1,
										}}
									>
										{Array.isArray(interviewSlots) &&
										interviewSlots.length > 0 ? (
											interviewSlots.map((slot) => (
												<Chip
													key={slot.id}
													label={format(
														new Date(slot.time),
														'dd.MM. HH:mm',
														{ locale: fi },
													)}
													onClick={() =>
														onSelect(slot)
													}
													variant="outlined"
													color="primary"
													sx={{
														cursor: 'pointer',
														'&:hover': {
															backgroundColor:
																theme.palette
																	.primary
																	.light,
														},
													}}
												/>
											))
										) : (
											<Typography
												variant="body2"
												color="text.secondary"
											>
												Ei vapaita haastatteluaikoja
											</Typography>
										)}
									</Box>
									<Button
										size="small"
										sx={{ mt: 2 }}
										onClick={() => setShowSlots(false)}
									>
										Piilota ajat
									</Button>
								</>
							)}
						</Box>
					)}
				</Box>

				{/* Ota yhteyttä -nappi */}
				<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
					<Button
						variant="outlined"
						startIcon={<EmailIcon />}
						href={`mailto:rekry@platform.lenz.fi?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}
						target="_blank"
						rel="noopener noreferrer"
						fullWidth
					>
						Ota yhteyttä
					</Button>
				</Box>
			</CardContent>
		</Card>
	);
};

export default React.memo(TalentCard, (prevProps, nextProps) => {
	return (
		prevProps.talent._id === nextProps.talent._id &&
		prevProps.match.score === nextProps.match.score &&
		prevProps.isSelected === nextProps.isSelected
	);
});
