import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import { SchoolOutlined, Chat } from '@mui/icons-material';

export const menuItemsConfig = {
	admin: [
		{ to: '/app', icon: DashboardIcon, primary: 'Dashboard' },
		{ to: '/app/companies', icon: BusinessIcon, primary: 'Yritykset' },
		{ to: '/app/talents', icon: PeopleIcon, primary: 'Talentit' },
		{
			to: '/app/recruit',
			icon: ManageAccountsIcon,
			primary: 'Hallitse rekryjä',
		},
		{
			to: '/app/chat-sessions',
			icon: Chat,
			primary: 'Chat-sessiot',
		},
		{ to: '/app/manage', icon: SettingsIcon, primary: 'Asetukset' },
	],
	talent: [
		{ to: '/app', icon: PeopleIcon, primary: 'Profiili' },
		{ to: '/app/recruit', icon: DashboardIcon, primary: 'Rekryt' },
		{ to: '/app/academy', icon: SchoolOutlined, primary: 'Tietopankki' },
	],
	company: [
		{ to: '/app/searchTalents', icon: PeopleIcon, primary: 'Hae' },
		{ to: '/app/talents', icon: PeopleIcon, primary: 'Talentit' },
	],
};
