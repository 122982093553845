import { useState, useCallback } from 'react';
import axios from '../services/axios';

export const useTalentChat = () => {
	const [messages, setMessages] = useState([]);
	const [matches, setMatches] = useState([]);
	const [projectData, setProjectData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [sessionId, setSessionId] = useState(null);

	const sendMessage = async (content) => {
		console.log('Setting loading state to true');
		setIsLoading(true);
		try {
			let response;
			if (!sessionId) {
				// Luo uusi chat-sessio, jos sessionId ei ole olemassa
				response = await axios.post('chatSessions', {
					message: content,
				});
				if (response?.data?.sessionId) {
					setSessionId(response.data.sessionId);
				}
			} else {
				// Lähetä viesti olemassa olevaan sessioon
				response = await axios.post(
					`chatSessions/${sessionId}/messages`,
					{
						message: content,
					},
				);
			}
			console.log('useTalentChat sendMessage response:', response);

			if (response?.data) {
				// Lisää AI:n vastaus kun se tulee
				if (response.data.message) {
					const aiResponse = {
						role: 'assistant',
						content: response.data.message,
					};
					setMessages((prev) => [...prev, aiResponse]);
				}

				return response;
			}
		} catch (error) {
			console.error('Error in useTalentChat sendMessage:', error);
			throw error;
		} finally {
			console.log('Setting loading state to false');
			setIsLoading(false);
		}
	};

	const resetChat = useCallback(() => {
		setMessages([]);
		setMatches([]);
		setProjectData({});
		setSessionId(null);
	}, []);

	return {
		messages,
		setMessages,
		matches,
		projectData,
		isLoading,
		sendMessage,
		resetChat,
		sessionId,
	};
};
