import React, { useState } from 'react';
import { Typography, Grid, Box, CircularProgress } from '@mui/material';
import { useChatSessions } from '../hooks/useChatSessions';
import ChatSessionCard from '../components/ChatSessionCard';
import ChatSessionDetailsModal from '../components/ChatSessionDetailsModal';

const ChatSessionListPage = () => {
	const [selectedSession, setSelectedSession] = useState(null);
	const { chatSessions, totalPages, currentPage, isLoading, error } =
		useChatSessions();

	const handleOpenSession = (session) => {
		setSelectedSession(session);
	};

	const handleCloseSession = () => {
		setSelectedSession(null);
	};

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="200px"
			>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		return (
			<Typography color="error">
				Virhe ladattaessa chat-sessioita: {error.message}
			</Typography>
		);
	}

	return (
		<Box>
			<Typography variant="h4" sx={{ mb: 3 }}>
				Chat-sessiot
			</Typography>

			<Grid container spacing={3}>
				{chatSessions.map((session) => (
					<Grid item xs={12} md={6} lg={4} key={session.id}>
						<ChatSessionCard
							session={session}
							onClick={() => handleOpenSession(session)}
						/>
					</Grid>
				))}
				{chatSessions.length === 0 && (
					<Grid item xs={12}>
						<Typography variant="body1" color="text.secondary">
							Ei aktiivisia chat-sessioita.
						</Typography>
					</Grid>
				)}
			</Grid>

			<ChatSessionDetailsModal
				session={selectedSession}
				open={!!selectedSession}
				onClose={handleCloseSession}
			/>
		</Box>
	);
};

export default ChatSessionListPage;
