import axios from './axios';

const getChatSessions = async () => {
	const response = await axios.get('/chatSessions?limit=100');
	return response;
};

const getChatSession = async (id) => {
	const response = await axios.get(`/chatSessions/${id}`);
	return response;
};

const updateChatSession = async (id, data) => {
	const response = await axios.put(`/chatSessions/${id}`, data);
	return response;
};

const deleteChatSession = async (id) => {
	const response = await axios.delete(`/chatSessions/${id}`);
	return response;
};

const sendMessage = async (sessionId, message) => {
	try {
		console.log('chatSessionService sending message:', {
			sessionId,
			message,
		}); // Debug
		const url = sessionId
			? `/chatSessions/${sessionId}/messages`
			: '/chatSessions';

		const response = await axios.post(url, message);
		console.log('chatSessionService response:', response); // Debug
		return response;
	} catch (error) {
		console.error('Error in chatSessionService sendMessage:', error);
		throw error;
	}
};

export default {
	getChatSessions,
	getChatSession,
	updateChatSession,
	deleteChatSession,
	sendMessage,
};
