import React, { useState, useEffect } from 'react';
import {
	Box,
	List,
	ListItem,
	ListItemText,
	IconButton,
	Divider,
	Button,
	Container,
	TextField,
	Typography,
	Drawer,
	Chip,
	Paper,
	keyframes,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import chatSessionService from '../../../services/chatSessionService';
import { useTalentChat } from '../../../hooks/useTalentChat';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/User/userSlice';
import SendIcon from '@mui/icons-material/Send';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TalentCard from './TalentCard';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../logo.png';
import { useNavigate } from 'react-router-dom';
import TypingIndicator from '../../../components/TypingIndicator';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// Lisää animaatiot
const glowAnimation = keyframes`
	0% {
		background-position: 0% 50%;
		box-shadow: 0 0 10px rgba(124, 85, 229, 0.5);
	}
	50% {
		background-position: 100% 50%;
		box-shadow: 0 0 20px rgba(250, 121, 33, 0.5);
	}
	100% {
		background-position: 0% 50%;
		box-shadow: 0 0 10px rgba(124, 85, 229, 0.5);
	}
`;

const pulseAnimation = keyframes`
	0% {
		transform: translateY(-50%) scale(1);
	}
	50% {
		transform: translateY(-50%) scale(1.1);
	}
	100% {
		transform: translateY(-50%) scale(1);
	}
`;

const TalentChatView = () => {
	const [sessions, setSessions] = useState([]);
	const [activeSessionId, setActiveSessionId] = useState(null);
	const [message, setMessage] = useState('');
	const [sessionMessages, setSessionMessages] = useState([]);
	const { messages, sendMessage, isLoading, setMessages, resetChat } =
		useTalentChat();
	const user = useSelector(selectUser);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [searchResults, setSearchResults] = useState(null);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
	const navigate = useNavigate();
	const [newResultsAvailable, setNewResultsAvailable] = useState(false);
	const [hasStartedChat, setHasStartedChat] = useState(false);
	const [localLoading, setLocalLoading] = useState(false);
	const inputRef = React.useRef(null);
	const messagesEndRef = React.useRef(null);

	// Käytä joko session viestejä tai uuden chatin viestejä
	const displayMessages = React.useMemo(() => {
		// Jos on aktiivinen sessio ja siinä viestejä, näytä ne
		if (activeSessionId && sessionMessages.length > 0) {
			return sessionMessages;
		}
		// Muuten näytä väliaikaiset viestit
		return messages;
	}, [activeSessionId, sessionMessages, messages]);

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	// Scrollaa alas kun viestit muuttuvat
	useEffect(() => {
		scrollToBottom();
	}, [displayMessages, isLoading, localLoading]);

	// Hae keskustelut
	useEffect(() => {
		const fetchSessions = async () => {
			try {
				const response = await chatSessionService.getChatSessions();
				setSessions(response.data.results || []);
			} catch (error) {
				console.error('Failed to fetch sessions:', error);
			}
		};
		fetchSessions();
	}, []);

	// Hae valitun session viestit ja matchit
	useEffect(() => {
		const fetchSessionMessages = async () => {
			if (activeSessionId) {
				try {
					const response =
						await chatSessionService.getChatSession(
							activeSessionId,
						);
					console.log('Session response:', response.data);

					if (response?.data) {
						// Päivitä viestit
						setSessionMessages(response.data.messages || []);
						// Aseta chat alkaneeksi kun on viestejä
						setHasStartedChat(true);

						// Tarkista matchit
						if (
							response.data.hasMatches &&
							response.data.matchCount > 0
						) {
							console.log(
								'Found matches in session:',
								response.data.matches,
							);
							// Muunna matchit oikeaan muotoon
							const formattedMatches = response.data.matches.map(
								(match) => ({
									talentId: match.talentId,
									talent: match.talent,
									score:
										match.finalScore ||
										match.matchPercentage / 100,
								}),
							);
							setSearchResults(formattedMatches);
							setDrawerOpen(true);
						}
					}
				} catch (error) {
					console.error('Failed to fetch session messages:', error);
				}
			} else {
				setSessionMessages([]);
				setSearchResults(null);
				setDrawerOpen(false);
			}
		};

		fetchSessionMessages();
	}, [activeSessionId]);

	// Viestin lähetys
	const handleSendMessage = async (e) => {
		console.log('handleSendMessage started');
		e.preventDefault();

		const messageToSend = message;
		setMessage('');

		if (!messageToSend.trim()) {
			console.log('Empty message, returning');
			return;
		}

		// Merkitse chat alkaneeksi heti kun ensimmäinen viesti lähetetään
		if (!hasStartedChat) {
			setHasStartedChat(true);
		}

		try {
			// Lisää käyttäjän viesti heti näkyviin
			const userMessage = { role: 'user', content: messageToSend };

			if (activeSessionId) {
				setLocalLoading(true);
				setSessionMessages((prev) => [...prev, userMessage]);
				const response = await chatSessionService.sendMessage(
					activeSessionId,
					{ message: messageToSend },
				);

				if (response?.data) {
					// Lisää AI:n vastaus kun se tulee
					const aiMessage = {
						role: 'assistant',
						content: response.data.message,
					};
					setSessionMessages((prev) => [...prev, aiMessage]);

					// Tarkista matchit
					if (
						response.data.hasMatches &&
						response.data.matches?.length > 0
					) {
						const formattedMatches = response.data.matches.map(
							(match) => ({
								talentId: match.talentId,
								talent: match.talent,
								score:
									match.finalScore ||
									match.matchPercentage / 100,
							}),
						);
						setSearchResults(formattedMatches);
						setNewResultsAvailable(true);
					}
				}
				setLocalLoading(false);
			} else {
				// Uusi chat
				setMessages((prev) => [...prev, userMessage]);
				const response = await sendMessage(messageToSend);

				if (response?.data) {
					setActiveSessionId(response.data.sessionId);
					// Hae session tiedot ja päivitä viestit
					const sessionResponse =
						await chatSessionService.getChatSession(
							response.data.sessionId,
						);

					if (sessionResponse.data) {
						setSessionMessages(sessionResponse.data.messages || []);

						if (sessionResponse.data.matches?.length > 0) {
							const formattedMatches =
								sessionResponse.data.matches.map((match) => ({
									talentId: match.talentId,
									talent: match.talent,
									score:
										match.finalScore ||
										match.matchPercentage / 100,
								}));
							setSearchResults(formattedMatches);
							setNewResultsAvailable(true);
						}
					}
				}
			}
		} catch (error) {
			console.error('Send message error:', error);
		} finally {
			setLocalLoading(false);
			// Aktivoi tekstikenttä viestin lähetyksen jälkeen
			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			}, 100);
		}
	};

	const handleNewChat = async () => {
		console.log('Starting new chat...');
		setActiveSessionId(null);
		setSessionMessages([]);
		setMessages([]);
		setSearchResults(null);
		setDrawerOpen(false);
		setHasStartedChat(false);
		resetChat();

		// Fokusoi tekstikenttään heti
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
				// Varmistetaan että tekstikenttä on näkyvissä
				inputRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100);

		try {
			const response = await chatSessionService.createChatSession();
			if (response?.data) {
				console.log('New chat session created:', response.data);
				setActiveSessionId(response.data.sessionId);
			}
		} catch (error) {
			console.error('Error creating new chat session:', error);
		}
	};

	const formatDate = (dateString) => {
		return new Date(dateString).toLocaleDateString('fi-FI', {
			day: 'numeric',
			month: 'numeric',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
		});
	};

	// Lisää näppäimistön käsittely
	const handleKeyPress = (e) => {
		console.log('Key pressed:', e.key, 'Shift:', e.shiftKey);
		if (e.key === 'Enter' && !e.shiftKey) {
			console.log('Enter pressed without shift, submitting');
			e.preventDefault();
			handleSendMessage(e);
		}
	};

	useEffect(() => {
		setSidebarOpen(!isMobile);
	}, [isMobile]);

	const renderWelcomeMessage = () => (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100%',
				textAlign: 'center',
				px: 2,
			}}
		>
			<Paper
				elevation={3}
				sx={{
					p: 4,
					bgcolor: 'background.paper',
					borderRadius: 2,
					maxWidth: 700,
				}}
			>
				<Typography variant="h4" gutterBottom>
					Tervetuloa Lenz Platformille – Nopein tapa löytää oikeat
					tekijät
				</Typography>
				<Typography variant="body1" sx={{ mb: 2 }}>
					Kuvaile tarpeesi, ja tekoälymme hoitaa loput. Lenz yhdistää
					yrityksesi tarpeet täydellisesti sopiviin asiantuntijoihin
					hyödyntäen tekoälyä. Oli kyseessä lyhyt projekti, äkillinen
					resurssitarve tai pidempiaikainen yhteistyö, saat vastaukset
					nopeasti ja riskittömästi.
				</Typography>
				<Typography variant="h6" color="primary">
					🚀 Kokeile nyt – löydä potentiaali hetkessä.
				</Typography>
			</Paper>
		</Box>
	);

	return (
		<Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'grey.50' }}>
			{/* Sivupalkki */}
			<Drawer
				variant={isMobile ? 'temporary' : 'permanent'}
				open={sidebarOpen}
				onClose={() => setSidebarOpen(false)}
				sx={{
					width: 280,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: 280,
						boxSizing: 'border-box',
						border: 'none',
						bgcolor: 'grey.100',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
						<Box
							component="img"
							sx={{ width: 53, cursor: 'pointer' }}
							alt="logo"
							src={logo}
							onClick={() => navigate('/')}
						/>
						<Typography
							variant="h4"
							noWrap
							sx={{ ml: 1, color: theme.palette.primary.main }}
						>
							Dynamis Ai
						</Typography>
					</Box>

					<Box sx={{ px: 1 }}>
						<Button
							startIcon={<AddIcon />}
							onClick={handleNewChat}
							variant="contained"
							sx={{
								width: '100%',
								py: 1,
								px: 2,
								mb: 2,
								borderRadius: 2,
								textTransform: 'none',
								boxShadow: 'none',
								backgroundColor: 'white',
								border: '1px solid rgba(0, 0, 0, 0.12)',
								color: 'text.primary',
								'&:hover': {
									backgroundColor: 'rgba(124, 85, 229, 0.04)',
									borderColor: '#7c55e5',
									color: '#7c55e5',
								},
							}}
						>
							Uusi keskustelu
						</Button>
					</Box>

					<Box sx={{ flex: 1, overflow: 'auto' }}>
						<List
							sx={{
								'& .MuiListItem-root': {
									borderRadius: 1,
									mb: 0.5,
								},
							}}
						>
							{sessions.map((session) => (
								<ListItem
									key={session.id}
									button
									selected={session.id === activeSessionId}
									onClick={() => {
										setActiveSessionId(session.id);
										if (isMobile) {
											setSidebarOpen(false);
										}
									}}
									sx={{
										'&.Mui-selected': {
											bgcolor: 'grey.200',
										},
									}}
								>
									<ListItemText
										primary={`Keskustelu ${session.messageCount} viestiä`}
										secondary={formatDate(
											session.lastActivity,
										)}
										primaryTypographyProps={{
											noWrap: true,
											fontSize: '0.9rem',
										}}
										secondaryTypographyProps={{
											fontSize: '0.8rem',
										}}
									/>
									{session.hasMatches && (
										<IconButton
											size="small"
											onClick={(e) => {
												e.stopPropagation();
												setActiveSessionId(session.id);
												setDrawerOpen(true);
											}}
										>
											<PersonSearchIcon fontSize="small" />
										</IconButton>
									)}
								</ListItem>
							))}
						</List>
					</Box>
				</Box>
			</Drawer>

			{/* Pääsisältö */}
			<Box
				component="main"
				sx={{
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					position: 'relative',
					height: '100vh',
				}}
			>
				{/* Yläpalkki mobiilissa */}
				{isMobile && (
					<Box
						sx={{
							p: 1,
							borderBottom: 1,
							borderColor: 'divider',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<IconButton onClick={() => setSidebarOpen(true)}>
							<MenuIcon />
						</IconButton>
					</Box>
				)}

				{/* Viestialue tai tervetuloviesti */}
				<Container
					sx={{
						flex: 1,
						overflowY: 'auto',
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
						py: 3,
						px: { xs: 2, sm: 4 },
						maxWidth: '60ch',
					}}
				>
					{!hasStartedChat && displayMessages.length === 0 ? (
						renderWelcomeMessage()
					) : (
						<>
							{displayMessages.map((msg, index) => (
								<Box
									key={index}
									sx={{
										display: 'flex',
										justifyContent:
											msg.role === 'user'
												? 'flex-end'
												: 'flex-start',
										width: '100%',
									}}
								>
									<Box
										sx={{
											maxWidth: '85%',
											minWidth: '20%',
											bgcolor:
												msg.role === 'user'
													? 'rgba(124, 85, 229, 0.1)'
													: 'rgba(250, 121, 33, 0.05)',
											border:
												msg.role === 'user'
													? '1px solid rgba(124, 85, 229, 0.2)'
													: '1px solid rgba(250, 121, 33, 0.1)',
											color: 'text.primary',
											p: 2,
											borderRadius: 2,
											boxShadow: 'none',
										}}
									>
										<ReactMarkdown
											remarkPlugins={[remarkGfm]}
											components={{
												p: ({ node, ...props }) => (
													<Typography
														component="div"
														sx={{
															fontSize: '0.95rem',
															lineHeight: 1.6,
															'& p': {
																margin: 0,
															},
															'& a': {
																color: 'primary.main',
																textDecoration:
																	'none',
																'&:hover': {
																	textDecoration:
																		'underline',
																},
															},
															'& code': {
																backgroundColor:
																	'rgba(0, 0, 0, 0.04)',
																padding:
																	'2px 4px',
																borderRadius: 1,
																fontSize:
																	'0.9em',
															},
															'& pre': {
																backgroundColor:
																	'rgba(0, 0, 0, 0.04)',
																padding: 2,
																borderRadius: 1,
																overflow:
																	'auto',
															},
															'& ul, & ol': {
																marginTop: 1,
																marginBottom: 1,
																paddingLeft: 3,
															},
															'& li': {
																marginBottom: 0.5,
															},
														}}
														{...props}
													/>
												),
											}}
										>
											{msg.content}
										</ReactMarkdown>
									</Box>
								</Box>
							))}
							{(isLoading || localLoading) && (
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'flex-start',
										width: '100%',
									}}
								>
									<Box
										sx={{
											maxWidth: '85%',
											minWidth: '20%',
											bgcolor: 'rgba(250, 121, 33, 0.05)',
											border: '1px solid rgba(250, 121, 33, 0.1)',
											borderRadius: 2,
											boxShadow: 'none',
										}}
									>
										<TypingIndicator />
									</Box>
								</Box>
							)}
							<Box ref={messagesEndRef} sx={{ height: 1 }} />
						</>
					)}
				</Container>

				{/* Viestin lähetys */}
				<Box
					component="form"
					onSubmit={handleSendMessage}
					sx={{
						p: 2,
						borderTop: 1,
						borderColor: 'divider',
						bgcolor: 'background.paper',
					}}
				>
					<Container maxWidth="60ch">
						<Box sx={{ display: 'flex', gap: 1 }}>
							<TextField
								inputRef={inputRef}
								fullWidth
								multiline
								maxRows={4}
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								onKeyPress={handleKeyPress}
								placeholder="Kirjoita viesti..."
								disabled={isLoading || localLoading}
								sx={{
									'& .MuiOutlinedInput-root': {
										borderRadius: 3,
									},
								}}
							/>
							<Button
								type="submit"
								variant="contained"
								disabled={
									isLoading || localLoading || !message.trim()
								}
								sx={{
									minWidth: 'auto',
									width: 56,
									height: 56,
									borderRadius: 3,
								}}
							>
								<SendIcon />
							</Button>
						</Box>
					</Container>
				</Box>
			</Box>

			{/* Tulokset Drawer pysyy samana */}
			<Drawer
				variant="temporary"
				anchor="right"
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
				sx={{
					'& .MuiDrawer-paper': {
						width: { xs: '90%', sm: 350, md: 500 },
						boxSizing: 'border-box',
						height: '100%',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						p: 2,
						borderBottom: 1,
						borderColor: 'divider',
					}}
				>
					<IconButton onClick={() => setDrawerOpen(false)}>
						<ChevronRightIcon />
					</IconButton>
					<Typography variant="h6" sx={{ ml: 2 }}>
						Sopivat osaajat
					</Typography>
				</Box>

				<Box
					sx={{
						overflow: 'auto',
						p: 2,
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
					}}
				>
					{searchResults?.map((result) => {
						if (!result?.talent) {
							console.log(
								'Missing talent data for result:',
								result,
							);
							return null;
						}

						return (
							<Box key={result.talentId || result.talent._id}>
								<Box
									sx={{
										mb: 1,
										display: 'flex',
										alignItems: 'center',
										gap: 1,
									}}
								>
									<Chip
										label={`Sopivuus: ${Math.round((result.score || 0) * 100)}%`}
										color="primary"
										variant="outlined"
									/>
								</Box>
								<TalentCard
									talent={result.talent}
									match={{
										score:
											result.score ||
											result.finalScore ||
											result.matchPercentage / 100 ||
											0,
									}}
									isSelected={false}
								/>
							</Box>
						);
					})}
				</Box>
			</Drawer>

			{/* Nappi drawerin avaamiseen */}
			{!drawerOpen && searchResults && (
				<IconButton
					onClick={() => {
						setDrawerOpen(true);
						setNewResultsAvailable(false);
					}}
					sx={{
						position: 'fixed',
						right: 0,
						top: '50%',
						transform: 'translateY(-50%)',
						background: 'linear-gradient(45deg, #fa7921, #7c55e5)',
						backgroundSize: '200% 200%',
						animation: `${glowAnimation} 3s ease infinite${newResultsAvailable ? `, ${pulseAnimation} 2s ease-in-out infinite` : ''}`,
						color: 'white',
						borderRadius: '4px 0 0 4px',
						boxShadow: '0 0 15px rgba(124, 85, 229, 0.3)',
						zIndex: 1200,
						padding: '12px 8px',
						transition: 'all 0.3s ease-in-out',
						'&:hover': {
							background:
								'linear-gradient(45deg, #7c55e5, #fa7921)',
							backgroundSize: '200% 200%',
							transform: 'translateY(-50%) scale(1.1)',
							boxShadow: '0 0 20px rgba(250, 121, 33, 0.5)',
						},
					}}
				>
					<ChevronLeftIcon sx={{ fontSize: 30 }} />
				</IconButton>
			)}
		</Box>
	);
};

export default TalentChatView;
