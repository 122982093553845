import React from 'react';
import { Box } from '@mui/material';

const TypingIndicator = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '40px',
				width: '100%',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					gap: '4px',
					padding: '8px 12px',
				}}
			>
				{[...Array(3)].map((_, i) => (
					<Box
						key={i}
						sx={{
							width: '8px',
							height: '8px',
							backgroundColor: '#7c55e5',
							borderRadius: '50%',
							animation: 'blink 0.8s ease-in-out infinite',
							animationDelay: `${i * 0.15}s`,
						}}
					/>
				))}
			</Box>
			<style>
				{`
          @keyframes blink {
            0% { transform: translateY(0px) }
            50% { transform: translateY(-6px) }
            100% { transform: translateY(0px) }
          }
        `}
			</style>
		</Box>
	);
};

export default TypingIndicator;
